import { useCartStore } from "~/lib/features/cart/store/cart_store"

export const useUserStore = defineStore("user-store", () => {
  var User = ref({
    isLoggedIn: false
  }), UserInfo = ref({});

  const setDefaultUser = () => {
    if (process.client) {
      if (localStorage.getItem('usersAccount')) {
        User.value = JSON.parse(localStorage.getItem('usersAccount'))
      }
      else {
        User.value.isLoggedIn = false
      }
    }
  }
  const SetUser = (user) => {
    User.value = user;
  }
  const SetUserInfo = ({ user }) => {
    UserInfo.value = user
  }

  const UpdateUserInfo = async () => {
    let { data: user, error } = await useFetch('self', {
      key: `GetSelfInfo`,
    })
    if (!error.value) {
      UserInfo.value = user.value
    }
  }

  const LogoutUser = async () => {
    useCookie('uat').value = null;
    UserInfo.value = {};
    User.value.isLoggedIn = false;
    localStorage.removeItem('usersAccount')
    navigateTo('/')

  }

  const GetUserInfo = async () => {
    await UpdateUserInfo();
  };

  return {
    User,
    UserInfo,
    SetUser,
    SetUserInfo,
    LogoutUser,
    UpdateUserInfo,
    GetUserInfo,
    setDefaultUser
  };
});